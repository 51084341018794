const firmHeaderProps = props => ({
  headerText: props.firm.data.name,
  internal: props.firm.data.internal,
  firmId: props.params.firmId,
  isAccountManager: props.currentUser.account_manager,
  organizationId: props.firm.data.organization_id,
  organizations: props.organizations,
  loadingOrganizations: props.loadingOrganizations,
  externalId: props.firm.data.external_id,
  firmType: props.firm.data.firm_type,
  billingAddress1: props.firm.data.billing_address1,
  billingAddress2: props.firm.data.billing_address2,
  billingCity: props.firm.data.billing_city,
  billingState: props.firm.data.billing_state,
  billingZip: props.firm.data.billing_zip,
  billingPhone: props.firm.data.billing_phone,
  website: props.firm.data.website
})

export default firmHeaderProps
