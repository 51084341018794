import AuthGateway from '../../../../shared_components/AuthGateway'

const QuarterbackSeatAdmin = ({children}) => (
  <div>
    <AuthGateway needsAccountManager={true}>
      <div>
        {children}
      </div>
    </AuthGateway>
  </div>
)

export default QuarterbackSeatAdmin
