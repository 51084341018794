/** @class AccountInformation
 *
 * @desc
 * Information about the status of each everplan owner and link to adding/inviting a client
 *
 * @todo information for a client that is pending approval (active client info)
 * @todo no longer use legacy resources for client information
 * @todo move AddToHousehold to v2
 *
 */

import {Component} from 'react'
import {connect} from 'react-redux'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import ActiveHouseholdUser from './ActiveHouseholdUser'
import InactiveHouseholdUser from './InactiveHouseholdUser'
import ShadowWrapper from '../../shared_firm/ShadowWrapper'
import AddToHousehold from './AddToHousehold'

import {currentUserConfig, userFirmOrganization} from '../../../../../lib/userTools'

import './accountInformation.scss'

export class AccountInformation extends Component {
  legacyClientInfo(clientSeat) { // temporary until all the components below use only v2 resources
    return this.props.clients.find(client => String(client.client_seat_id) === clientSeat.get('id'))
  }

  render() {
    /*
      The class name below is a temporary workaround to avoid styling collisions with an identically-named
      component in the dashboard world. I'm going to do a PR just focused on renaming this component and
      subdirectory to match the class and permanently avoid the collision. --BLR
    */
    return (
      <span className='account-information' data-testid='account-information'>
        <h3>Account Information</h3>
        <div className='row-wrapper individual-client'>
          {
            this.props.clientSeats.map(
              clientSeat => (
                <ShadowWrapper className='account-details' key={clientSeat.get('id')}>
                  {
                    clientSeat.get('status') === 'Active' ?
                      <ActiveHouseholdUser client={this.legacyClientInfo(clientSeat)} /> :
                      <InactiveHouseholdUser
                        client={this.legacyClientInfo(clientSeat)}
                        clients={this.props.clients}
                        clientSeat={clientSeat}
                        clientSeats={this.props.clientSeats}
                        clientSeatIdInParam={this.props.params.clientSeatId}
                        organization={this.props.organization}
                        userConfig={this.props.userConfig}
                      />
                  }
                </ShadowWrapper>
              )
            )
          }
          {
            !this.props.userConfig.get('quarterback') &&
            this.props.clientSeats.size === 1 &&
            <AddToHousehold
              client={this.props.clients[0]}
              clients={this.props.clients}
              clientSeat={this.props.clientSeats.first()}
              userConfig={this.props.userConfig}
              organization={this.props.organization}
            />
          }
        </div>
      </span>
    )
  }
}

AccountInformation.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      client_seat_id: PropTypes.number,
      status: PropTypes.string
    })
  ),
  params: PropTypes.shape({
    clientSeatId: PropTypes.string
  }),
  clientSeats: PropTypes.instanceOf(List),
  organization: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = state => ({
  organization: userFirmOrganization({...state}),
  userConfig: currentUserConfig(state.api)
})

export default connect(mapStateToProps)(AccountInformation)
