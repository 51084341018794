import PropTypes from 'prop-types'
import React from 'react'

import AuthGateway from '../../../../shared_components/AuthGateway'
export default class QuarterbackAdmin extends React.Component {
  render() {
    return (
      <div className='quarterback-admin' data-testid='quarterback-admin'>
        <AuthGateway needsQuarterbackUser={true}>
          <div>
            {this.props.children}
          </div>
        </AuthGateway>
      </div>
    )
  }
}

QuarterbackAdmin.propTypes = {
  children: PropTypes.node
}
