import PropTypes from 'prop-types'

import SelectFilter from '../../shared_components/SelectFilter'
import SummariesTable from '../../shared_components/summaries_table/SummariesTable'

import {monthDayYear} from '../../../../../lib/dateTools'

import './quarterbackSeatsTable.scss'

const QuarterbackSeatsTable = props => {
  const columns = [
    {
      Header: 'Quarterback',
      accessor: 'name',
      minWidth: 90,
      filterable: true
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: columnProps => (
        <span className={`${columnProps.value} table-status`}>
          {columnProps.value}
        </span>
      ),
      minWidth: 60,
      Filter: columnProps => (
        <SelectFilter
          {...columnProps}
          options={[
            {name: 'Draft', id: '0'},
            {name: 'Pending', id: '1'},
            {name: 'Active', id: '2'}
          ]}
        />
      ),
      filterable: true
    },
    {
      id: 'created-at',
      Header: 'Date Added',
      minWidth: 60,
      accessor: quarterbackSeat => monthDayYear(quarterbackSeat['created-at'], 'L')
    },
    {
      id: 'last-login-at',
      Header: 'Last Login',
      minWidth: 60,
      accessor: quarterbackSeat => monthDayYear(quarterbackSeat['last-login-at'], 'L')
    }
  ]

  return (
    <SummariesTable
      {...props}
      className='quarterback-seats-table'
      data={props.quarterbackSeats}
      dataTestid='quarterback-seats-table'
      defaultSorted={[{id: 'created-at', desc: true}]}
      columns={columns}
      loading={props.loading}
    />
  )
}

QuarterbackSeatsTable.propTypes = {
  loading: PropTypes.bool,
  onFetchData: PropTypes.func,
  onRowClicked: PropTypes.func,
  pages: PropTypes.number,
  quarterbackSeats: PropTypes.array
}

export default QuarterbackSeatsTable

