import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {List, Map} from 'immutable'
import {readEndpoint} from 'redux-json-api'
import {useEffect} from 'react'

import {useEverplanData} from '../../../../../lib/hooks'

import bindResource from '../../../../../shared_components/BindToStores'
import {cleanResponse} from '../../../../../dashboard/src/components/corp_max/redux/apiHelper'
import ClientActions from '../../../actions/ClientActions'
import Container from '../../../../../lib/Container'
import {currentUserConfig} from '../../../../../lib/userTools'
import storePrototype from '../../../../../shared_components/StorePrototype'
import QuarterbackClient from './QuarterbackClient'

import {
  findRawResourceByAttributeId,
  filterRawResourceByEverplanId,
  findResourceByAttributeId,
  findResourceById,
  filterRawResourceByAttributeId
} from '../../../../../lib/plan_data/userData'
import NewLoader from '../../../../../shared_components/NewLoader'
import {everplanOwnerships} from '../../../../../lib/plan_data/userData'

const registeredActionTypes = [
  ClientActions.Types.DID_UPDATE_CLIENT,
  ClientActions.Types.GOT_HOUSEHOLD
]

Container.registerAction('household', ClientActions)
Container.registerStore('household', storePrototype(registeredActionTypes))

const QuarterbackClientContainer = props => {
  const {itemResponses, loadingEverplanData, newContacts} = useEverplanData(props.everplan.get('id'))

  useEffect(() => {
    props.readEndpoint(`client-seats/${props.params.clientSeatId}?include=everplan-summary,firm.firm-config`)
      .then(seatResponse => {
        const everplanId = seatResponse.body.data.attributes['everplan-id']
        props.readEndpoint(`item-views?filter[everplan_id]=${everplanId}`)

        // Need to re-fetch client-seats incase a household or batch changes/additions were made from the client page (which uses v1 endpoints),
        // otherwise there will only be new information on the client seat that is in the params. --ZD
        props.readEndpoint(
          `everplans/${everplanId}?include=firm-ownership.secondary-everplan-owner,request-item-permissions-invite,client-seats`
        ).then(response => {
          props.readEndpoint(`client-households/${response.body.data.relationships['firm-ownership'].data.id}`)
        })
      })
  }, [])

  const loading = props.clientSeats.isEmpty() ||
  props.everplanSummary.isEmpty() ||
  props.owners.isEmpty() ||
  props.loadingHousehold ||
  loadingEverplanData

  if (loading) {
    return <NewLoader loading={true} />
  } else {
    return (
      <QuarterbackClient
        {...props}
        itemResponses={itemResponses}
        newContacts={newContacts}
      />
    )
  }
}

QuarterbackClientContainer.defaultProps = {
  household: {
    data: {
      advisor: {name: ''},
      clients: [],
      firm_write: false,
      name: '',
      plan_stats: [],
      user_id: null
    },
    errors: [],
    message: null
  },
  loadingHousehold: true
}

QuarterbackClientContainer.propTypes = {
  firm: PropTypes.instanceOf(Map),
  clientSeat: PropTypes.instanceOf(Map),
  clientSeats: PropTypes.instanceOf(List),
  everplan: PropTypes.instanceOf(Map),
  everplanSummary: PropTypes.instanceOf(Map),
  household: PropTypes.object,
  loadingHousehold: PropTypes.bool,
  owners: PropTypes.instanceOf(List),
  params: PropTypes.shape({
    clientSeatId: PropTypes.string
  }),
  readEndpoint: PropTypes.func
}

const mapStateToProps = ({api}, ownProps) => {
  const clientSeat = findResourceById({
    resourceList: cleanResponse(api['client-seats']),
    id: ownProps.params.clientSeatId
  })
  const firmId = clientSeat.get('firm-id')

  const clientSeats = filterRawResourceByEverplanId({
    id: clientSeat.get('everplan-id'),
    rawResource: api['client-seats']
  })

  const currentUser = currentUserConfig(api)

  const everplan = findResourceById({
    resourceList: cleanResponse(api.everplans),
    id: clientSeat.get('everplan-id')
  })

  const everplanSummary = findResourceById({
    resourceList: cleanResponse(api['everplan-summaries']),
    id: clientSeat.get('everplan-id')
  })

  const owners = everplanOwnerships({secondaryEverplanOwners: api['secondary-everplan-owners'], everplanPreviewId: clientSeat.get('everplan-id'), owners: List()})

  return ({
    firm: findResourceById({resourceList: cleanResponse(api.firms), id: firmId}),
    firmConfig: findRawResourceByAttributeId({
      rawResource: api['firm-configs'],
      attribute: 'firm-id',
      id: firmId
    }),
    clientSeat,
    clientSeats,
    currentUser,
    everplan,
    everplanSummary,
    firmOwnership: findResourceByAttributeId({
      attribute: 'everplan-id',
      id: parseInt(clientSeat.get('everplan-id'), 10),
      resourceList: cleanResponse(api['firm-ownerships'])
    }),
    itemViews: filterRawResourceByAttributeId({
      attribute: 'everplan-id',
      id: clientSeat.get('everplan-id'),
      rawResource: api['item-views']
    }),
    owners
  })
}

// Legacy resource fetching that is needed for the shared functionality of the CM
// client detail as well as the legacy page:
const BoundQuarterbackClientContainer = bindResource(
  QuarterbackClientContainer,
  [
    {
      id: 'clientSeatId',
      name: 'household',
      type: 'item'
    }
  ]
)
export default connect(mapStateToProps, {readEndpoint})(BoundQuarterbackClientContainer)

