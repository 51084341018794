import classnames from 'classnames'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'

import './summariesTable.scss'

const SummariesTable = ({className, dataTestid, onRowClick, ...otherProps}) => (
  <ReactTable
    {...otherProps}
    manual
    className={classnames('summaries-table -striped -highlight', className)}
    resizable={false}
    getTableProps={() => ({'data-testid': dataTestid})}
    getTdProps={(state, rowInfo) => ({
      onClick: () => onRowClick(rowInfo.original.id)
    })}
  />
)

SummariesTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  dataTestid: PropTypes.string,
  columns: PropTypes.array,
  onRowClick: PropTypes.func
}

export default SummariesTable
