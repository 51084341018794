import {Link} from 'react-router'
import PropTypes from 'prop-types'
import React from 'react'

import AccountDropdown from './AccountDropdown'
import AdvisorSetting from './AdvisorSetting'
import * as Links from '../../../../shared_components/Links'


export default class LoggedInMenu extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var firmLinkDestination
    var firmLinkText

    const firmUserOnlyView = !this.props.currentUser.account_manager && !this.props.currentUser.quarterback

    if (this.props.currentUser.account_manager) {
      firmLinkDestination = '/pro/firm-admin/all-firms'
      firmLinkText = ' All Firms'
    } else {
      firmLinkDestination = `/pro/firm/${this.props.currentUser.firm}/users`
      firmLinkText = ' Firm Settings'
    }

    return (
      <ul className='menu row-wrapper-sb'>
        {
          firmUserOnlyView &&
          (
            <li className='menu-item'>
              <Link activeClassName='active' to='/pro' className='header-link'>Client List</Link>
            </li>
          )
        }
        {
          firmUserOnlyView && this.props.professionalDesignation &&
          (
            <li className='menu-item'>
              <AdvisorSetting advisors={this.props.advisors} firm={this.props.currentUser.firm} professionalDesignation={this.props.professionalDesignation} />
            </li>
          )
        }
        {
          !this.props.currentUser.quarterback &&
          (
            <li className='menu-item'>
              <Link activeClassName='active' to={firmLinkDestination} className='header-link'>{firmLinkText}</Link>
            </li>
          )
        }
        {
          this.props.currentUser.account_manager &&
          (
            <li className='menu-item'>
              <Link activeClassName='active' to='/pro/organization-admin/organizations' className='header-link'>Organizations</Link>
            </li>
          )
        }
        {
          this.props.currentUser.account_manager &&
          (
            <li className='menu-item'>
              <Link
                activeClassName='active'
                to='/pro/quarterback-seat-admin/quarterback-seats'
                className='header-link'>
                Quarterbacks
              </Link>
            </li>
          )
        }
        {
          this.props.currentUser.firm_user &&
          (
            <li className='menu-item'>
              <Links.ProResourceCenter className='header-link'>Resources</Links.ProResourceCenter>
            </li>
          )
        }
        {
          this.props.currentUser.quarterback &&
          (
            <li className='menu-item'>
              <Link
                activeClassName='active'
                to='/pro/quarterback-admin/clients'
                className='header-link'>
                Families
              </Link>
            </li>
          )
        }
        <li className='menu-item'><AccountDropdown currentUser={this.props.currentUser} /></li>
      </ul>
    )
  }
}

LoggedInMenu.propTypes = {
  currentUser: PropTypes.shape({
    firm: PropTypes.number,
    account_manager: PropTypes.bool,
    firm_user: PropTypes.bool,
    quarterback: PropTypes.bool
  }),
  advisors: PropTypes.array,
  professionalDesignation: PropTypes.string
}
