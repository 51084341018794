import PropTypes from 'prop-types'

import {List} from 'immutable'
import {useState} from 'react'
import {connect} from 'react-redux'

import {Link, Stack, Typography} from '@mui/material'

import {useDeputyData, useEverplanData} from '../../../../../lib/hooks'

import AddDeputyModal from '../../../../../dashboard/src/components/corp_max_deputies/add/AddDeputyModal'

import {currentUserConfig} from '../../../../../lib/userTools'

import './addDeputyForClient.scss'

const AddDeputyForClient = props => {
  const {deputies} = useDeputyData(props.everplanId)
  const {itemResponses, newContacts} = useEverplanData(props.everplanId)

  const [isAddDeputyModalVisible, setIsAddDeputyModalVisible] = useState(false)

  return (
    <Stack className='add-deputy-for-client' direction='row' spacing={2}>
      <Typography>You can add Deputies to this Everplan.</Typography>
      <Link
        component='button'
        onClick={() => setIsAddDeputyModalVisible(true)}
        underline='hover'
        variant='body1'>
        Add a Deputy
      </Link>
      {
        isAddDeputyModalVisible && (
          <AddDeputyModal
            context='pro_client_dashboard'
            deputies={deputies}
            exit={() => setIsAddDeputyModalVisible(false)}
            itemResponses={itemResponses}
            newContacts={newContacts}
            userConfig={props.userConfig}
          />
        )
      }
    </Stack>
  )
}

AddDeputyForClient.propTypes = {
  deputies: PropTypes.instanceOf(List),
  everplanId: PropTypes.number,
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = state => ({userConfig: currentUserConfig(state.api)})

export default connect(mapStateToProps)(AddDeputyForClient)
