import PropTypes from 'prop-types'

import {List, Map} from 'immutable'
import {useEffect} from 'react'

import AccountInformation from '../../shared_components/account_information/AccountInformation'
import AccountSummary from '../../shared_components/account_summary/AccountSummary'
import ClientActions from '../../../../../pro/src/actions/ClientActions'
import EverplanDetails from '../../shared_components/everplan_details/EverplanDetails'
import QuarterbackHeading from './QuarterbackHeading'
import RemoveClientController from '../../shared_components/remove_clients/RemoveClientController'
import storeWrapper from '../../../../../shared_components/storeWrapper'

import {
  useNotificationContext
} from '../../../../../shared_components/notifications/NotificationContext'

import {usePrevious} from '../../../../../lib/hooks'

import {allUsersDeceased} from '../../../../../lib/tools'

import './quarterbackClient.scss'

const QuarterbackClient = props => {
  const prevClientInformation = usePrevious(props.editClientNotification.data)
  const {isDifferent} = useNotificationContext()

  useEffect(() => {
    isDifferent(
      props.editClientNotification.data,
      prevClientInformation
    ).shortSuccess('Client information updated.')
  }, [props.editClientNotification.data])

  const isAnyEverplanUserActive = props.clientSeats.some(clientSeat => clientSeat.get('status') === 'Active')

  return (
    <div className='quarterback-client'>
      {props.children}
      <QuarterbackHeading
        name={props.household.data.name}
        firmName={props.firm.get('name')}
      />
      <AccountInformation
        clientSeats={props.clientSeats}
        clients={props.household.data.clients}
        params={props.params}
      />
      <AccountSummary
        anyoneActive={isAnyEverplanUserActive}
        allUsersDeceased={allUsersDeceased(props.household.data.clients)}
        currentUser={props.currentUser}
        data={props.household}
        everplanSummary={props.everplanSummary}
        firmOwnership={props.firmOwnership}
      />
      <EverplanDetails {...props} everplanSummary={props.everplanSummary} />
      <RemoveClientController {...props} />
    </div>
  )
}

QuarterbackClient.propTypes = {
  clientSeats: PropTypes.instanceOf(List),
  clients: PropTypes.array,
  editClientNotification: PropTypes.shape({
    data: PropTypes.shape({
      message: PropTypes.string
    })
  }),
  everplanSummary: PropTypes.instanceOf(Map),
  firm: PropTypes.instanceOf(Map),
  household: PropTypes.object,
  params: PropTypes.object,
  firmOwnership: PropTypes.instanceOf(Map),
  currentUser: PropTypes.instanceOf(Map),
  name: PropTypes.string
}

export default storeWrapper(
  QuarterbackClient,
  [{
    actionTypes: [ClientActions.Types.DID_UPDATE_CLIENT],
    name: 'editClientNotification'
  }]
)
