/** @class AccountSummary
 *
 * @desc
 * Displays summary information about the client Everplan being viewed.
 *
 * @todo Wire up the following with v2 resources:
 *.   - Deputization status text
 *.   - View Everplan link
 *.   - Statistics
 */

import PropTypes from 'prop-types'
import {Map} from 'immutable'

import AddDeputyForClient from './AddDeputyForClient'
import ItemPermissionsContainer from '../../request_item_permissions/ItemPermissionsContainer'
import ShadowWrapper from '../../shared_firm/ShadowWrapper'
import Statistics from './Statistics'
import DeputyInformation from './DeputyInformation'

import './accountSummary.scss'

const AccountSummary = props => {
  if (props.anyoneActive) {
    return (
      <div className='account-summary' data-testid='account-summary'>
        <h3>Everplan Summary</h3>
        <ShadowWrapper>
          <ul>
            {
              // TODO - the following will be finalized when TK-2111 is worked on.
              props.currentUser.get('can-add-deputy-for-client') &&
              <li>
                <AddDeputyForClient
                  everplanId={props.everplanSummary.get('id')}
                />
              </li>
            }
            {
              !props.currentUser.get('quarterback') &&
              <li>
                <DeputyInformation
                  currentUser={props.currentUser}
                  currentUserIsDeputy={props.data.current_user_is_deputy}
                  everplanSummary={props.everplanSummary}
                />
              </li>
            }
            <li>
              <ItemPermissionsContainer {...props} />
            </li>
          </ul>
          <Statistics stats={props.data} everplanSummary={props.everplanSummary} />
        </ShadowWrapper>
      </div>
    )
  } else {
    return null
  }
}

AccountSummary.defaultProps = {
  allUsersDeceased: false,
  anyoneActive: false, // Hide module initially to prevent flicker if no Everplanners are active. --BLR
  data: {
    current_user_is_deputy: false,
    user_id: null
  }
}

AccountSummary.propTypes = {
  allUsersDeceased: PropTypes.bool.isRequired,
  anyoneActive: PropTypes.bool.isRequired,
  currentUser: PropTypes.instanceOf(Map),
  data: PropTypes.shape({
    current_user_is_deputy: PropTypes.bool,
    user_id: PropTypes.number
  }),
  everplanSummary: PropTypes.instanceOf(Map),
  firmOwnership: PropTypes.instanceOf(Map)
}

export default AccountSummary
