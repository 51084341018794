import {useEffect, Fragment, useCallback} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {readEndpoint} from 'redux-json-api'

import ClientStat from './ClientStat'
import {Text} from '../../../../../dashboard/src/components/blocks/Texts'
import Loader from '../../../../../shared_components/NewLoader'

import {cleanResponse} from '../../../../../dashboard/src/components/corp_max/redux/apiHelper'
import {isFuneralHome} from '../../../lib/firmTools'
import {scrollComponentToView} from '../../../../../lib/scrollTools'
import {currentUserConfig} from '../../../../../lib/userTools'

import './clientAnalytics.scss'

const DEFAULT_STATUS_FIELDS = ['active', 'pending', 'draft']
const FUNERAL_HOME_STATUS_FIELDS = ['active', 'pending', 'deceased']

export const ClientsAnalytics = ({
  clientStats, firm, userConfig, ...otherProps
}) => {
  const CLIENT_ANALYTICS_FIELDS = isFuneralHome(firm) ?
    FUNERAL_HOME_STATUS_FIELDS :
    DEFAULT_STATUS_FIELDS

  useEffect(() => {
    if (clientStats.isEmpty())
      otherProps.readEndpoint(`client-stats/${userConfig.get('firm-id')}`)
  }, [clientStats.size, userConfig.size])

  const clientTotal = clientStat => clientStat.reduce((sum, value, key) => {
    if (CLIENT_ANALYTICS_FIELDS.includes(key))
      return sum + value
    else
      return sum
  }, 0)

  const scrollToClassName = isFuneralHome(firm) ?
    'funeral-home-clients-summaries-table-container' :
    'dash-with-advisors'

  const scrollToClients = useCallback(
    () => scrollComponentToView(scrollToClassName)
  )

  const clientsLinkText = isFuneralHome(firm) ? 'All Families' : 'All Clients'

  return (
    <div className='clients-analytics'>
      <Loader loading={clientStats.isEmpty()}>
        <div className='content'>
          <Text className='heading flex-container'>
            <h1>Summary</h1>
            <a onClick={scrollToClients}>{`${clientsLinkText} »`}</a>
          </Text>
          <div className='stats flex-container'>
            {clientStats.size > 0 && (
              <Fragment>
                <ClientStat
                  value={clientTotal(clientStats)}
                  scrollToClients={scrollToClients}
                  label='all'
                />
                {clientStats
                  .filter((_val, key) => CLIENT_ANALYTICS_FIELDS.includes(key))
                  .keySeq()
                  .map(key => (
                    <ClientStat
                      key={key}
                      label={key}
                      value={clientStats.get(key)}
                      scrollToClients={scrollToClients}
                    />
                  ))}
              </Fragment>
            )}
          </div>
        </div>
      </Loader>
    </div>
  )
}

ClientsAnalytics.propTypes = {
  clientStats: PropTypes.instanceOf(Map),
  firm: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = ({api}) => ({
  clientStats: cleanResponse(api['client-stats']).first() || Map(),
  userConfig: currentUserConfig(api)
})

export default connect(mapStateToProps, {readEndpoint})(ClientsAnalytics)
