/** @class Categories
 *
 * @desc
 * Class that displays all the `Categories` in the `Everplan` being viewed.
 */
import {Component} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import CategoryDetails from './CategoryDetails'
import Form from '../../../../../shared_components/forms/v2/Form'
import Fields from '../../../../../shared_components/forms/v2/fields/index'

import './categories.scss'

class Categories extends Component {
  constructor(props) {
    super(props)

    this.state = {categories: this.defaultCategory()}

    this.onChange = this.onChange.bind(this)
  }

  categoryItemList() {
    return this.props.categories
      .sortBy(category => category.get('sort-order'))
      .map(category => Map({
        label: category.get('name'),
        value: category.get('name'),
        id: category.get('id')
      }))
      .unshift(Map({label: 'All Categories', value: 'All Categories', id: 'all'}))
  }

  getCategoryByName(name) {
    return List([this.props.categories.find(category => category.get('name') === name)])
  }

  onChange() {
    const value = this.refs.form.formData().getIn(['categories', 'value'])
    if (value === 'All Categories')
      this.setState({categories: this.props.categories})
    else
      this.setState({categories: this.getCategoryByName(value)})
  }

  isQuarterbackOrFuneralHome() {
    return this.props.currentUser.get('quarterback') ||
    this.props.firm.get('firm-type') === 'funeral_home'
  }

  defaultCategory() {
    let category = this.props.categories
    const value = this.defaultCategoryValue()

    if (value !== 'All Categories')
      category = this.getCategoryByName(value)

    return category
  }

  defaultCategoryValue() {
    return this.isQuarterbackOrFuneralHome() ?
      'After I\'m Gone' :
      'All Categories'
  }

  render() {
    return (
      <div className='categories forms-playground'>
        <header className='categories-header'>
          <h3>Everplan Details</h3>
          <span className='category-selection-container flex-container'>
            <span className='category-selection-prompt'>Show: </span>
            <Form ref='form' className='core category-selection'>
              <Fields.Select
                data={
                  Map({
                    id: 'categories',
                    items: this.categoryItemList(),
                    legend: '',
                    name: 'categories',
                    value: this.defaultCategoryValue()
                  })
                }
                onChange={this.onChange}
              />
            </Form>
          </span>
        </header>
        {
          this.state.categories.sortBy(category => category.get('sort-order')).map(
            category => <CategoryDetails key={category.get('id')} {...this.props} category={category} />
          )
        }
      </div>
    )
  }
}

Categories.propTypes = {
  categories: PropTypes.instanceOf(List),
  currentUser: PropTypes.instanceOf(Map),
  firm: PropTypes.instanceOf(Map),
  owners: PropTypes.instanceOf(List)
}

export default Categories
