import PropTypes from 'prop-types'
import {useFormContext, Controller} from 'react-hook-form'
import {InputAdornment, Box} from '@mui/material'
import {useState, useMemo} from 'react'

import TextInput from './TextInput'
import MaskTextButton from '../MaskTextButton'
import PasswordRequirements from './PasswordRequirements'
import './password.scss'

const Password = ({
  label,
  name,
  placeholder,
  showRequirements = true,
  validationRules = {}
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showFlyout, setShowFlyout] = useState(false)
  const {control, formState: {errors}, watch} = useFormContext()

  const password = watch(name)

  const validations = useMemo(() => {
    if (!showRequirements) return {}

    return {
      length: password?.length >= 8,
      uppercase: (/[A-Z]/).test(password),
      lowercase: (/[a-z]/).test(password),
      number: (/[0-9]/).test(password)
    }
  }, [password, showRequirements])

  return (
    <Box
      className='password-field-container'
      onFocus={() => setShowFlyout(true)}
      onBlur={() => setShowFlyout(false)}
    >
      <Controller
        name={name}
        control={control}
        defaultValue=''
        rules={{
          required: 'Please add a password.',
          validate: validationRules.validate
        }}
        render={({field}) => (
          <TextInput
            {...field}
            autoComplete='new-password'
            id={name}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <MaskTextButton
                    showText={showPassword}
                    toggleButton={() => setShowPassword(!showPassword)}
                  />
                </InputAdornment>
              )
            }}
            label={label}
            type={showPassword ? 'text' : 'password'}
            error={!!errors[name]}
            helperText={errors[name]?.message}
            placeholder={placeholder}
          />
        )}
      />
      {showRequirements && showFlyout && (
        <PasswordRequirements validations={validations} />
      )}
    </Box>
  )
}

Password.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  showRequirements: PropTypes.bool,
  validationRules: PropTypes.shape({
    validate: PropTypes.func
  })
}

export default Password
