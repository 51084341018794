import {IndexRedirect, Route, Redirect} from 'react-router'
import storePrototype from '../../../shared_components/StorePrototype'

import AddIndividualClient from '../components/clients/AddIndividualClient'
import AddHouseholdClients from '../components/clients/AddHouseholdClients'
import AddBulkClients from '../components/clients/add_many/AddBulkClients'
import App from '../components/ProApp'
import Client from '../components/clients/Client'
import Clients from '../components/clients/index/Clients'
import ConfirmAddBulkClients from '../components/clients/add_many/ConfirmAddBulkClients'
import InviteSettingsActions from '../actions/InviteSettingsActions'
import PickAdder from '../components/clients/PickAdder'

// Bulk
import CustomizeBulkClientInviteController from '../components/bulk/CustomizeBulkClientInviteController'
import LegacyBulkSendOrResendController from '../components/bulk/LegacyBulkSendOrResendController'
import BulkSendOrResendController from '../components/bulk/BulkSendOrResendController'
import PickRecipientsController from '../components/bulk/PickRecipientsController'
import PickDraft from '../components/bulk/PickDraft'
import PickPending from '../components/bulk/PickPending'
import SelectAdvisorController from '../components/bulk/SelectAdvisorController'
import {SelectInviteAdvisor, SelectReinviteAdvisor} from '../components/bulk/LegacySelectAdvisor'

// Account manager related
import FirmUserConfirm from '../components/onboarding/FirmUserConfirm'
import QuarterbackUserConfirm from '../components/onboarding/QuarterbackUserConfirm'

import bindResources from '../../../shared_components/BindToStores'

// Firms:
import Firm from '../components/shared_firm/Firm'
import FirmActions from '../actions/FirmActions'
import FirmAdmin from '../components/firm_admin/FirmAdmin'
import FirmConfigController from '../components/shared_firm/FirmConfigController'
import FirmLicenses from '../components/firm_admin/FirmLicenses'
import FirmSummariesTableContainer from '../components/firm_admin/firms_table/FirmSummariesTableContainer'

import FirmUsers from '../components/firm_admin/FirmUsers'

// Firm users:
import AccountInfo from '../components/shared_firm/AccountInfo'
import ContactCard from '../components/shared_firm/ContactCard'
import FirmUser from '../components/shared_firm/FirmUser'
import InvitationMessage from '../components/shared_firm/InvitationMessage'
import Integrations from '../components/shared_firm/Integrations'

// Household:
import HouseholdContainer from '../components/households/HouseholdContainer'


// OAuth tbd by BJK
import Authorizations from '../components/oauth/Authorizations'
import Permissions from '../components/oauth/Permissions'

// Corp Max
import FirmContainer from '../components/FirmContainer'
import {MetaDataContainerWithUserConfigAndUser} from '../../../dashboard/src/components/corp_max/everplan_preview/MetaDataContainer'
import InviteClientModalContainer from '../components/shared_components/invite_client/InviteClientModalContainer'

// Corp Max Pro Edit:
import {addMore, newItem, editItem} from '../../../dashboard/src/components/corp_max/item/ItemFormController'
import CompoundResponseGroup from '../../../dashboard/src/components/corp_max/item/CompoundResponseGroup'
import ItemForm from '../../../dashboard/src/components/corp_max/item/ItemForm'
import ProItemController from '../components/shared_components/everplan_details/ProItemController'
import ViewItem from '../../../dashboard/src/components/corp_max/item/ViewItem'

// Organizations
import Organization from '../components/organization_admin/Organization'
import OrganizationAdmin from '../components/organization_admin/OrganizationAdmin'
import OrganizationConfig from '../components/organization_admin/organization_config/OrganizationConfig'
import OrganizationsTableContainer from '../components/organization_admin/organizations_table/OrganizationsTableContainer'
import Overview from '../components/organization_admin/Overview'

// QuarterbackSeat Admin
import QuarterbackSeat from '../components/quarterback_seat_admin/quarterback_seat/QuarterbackSeat'
import QuarterbackSeatAdmin from '../components/quarterback_seat_admin/QuarterbackSeatAdmin'
import QuarterbackSeatsTableContainer from '../components/quarterback_seat_admin/quarterback_seats_table/QuarterbackSeatsTableContainer'

// Quarterback Admin
import QuarterbackAdmin from '../components/quarterback_admin/QuarterbackAdmin'
import QuarterbackClientSummariesContainer from '../components/quarterback_admin/clients_table/QuarterbackClientSummariesContainer'
import QuarterbackClientContainer from '../components/quarterback_admin/quarterback_client/QuarterbackClientContainer'

/* Might need a better way of handling this-- but we need to register stores BEFORE the routes bind to them */
import Container from '../../../lib/Container'
import ClientStore from '../stores/ClientStore'
import InviteStore from '../stores/InviteStore'

Container.registerStore('client', ClientStore)
Container.registerStore('invite', InviteStore)
Container.registerStore('inviteSettings', storePrototype(InviteSettingsActions.Types.GOT_INVITE_SETTINGS))

// Firms-related registrations:
Container.registerAction('firm_users', FirmActions)
Container.registerAction('firm_user', FirmActions)
Container.registerStore('newFirm', storePrototype(FirmActions.Types.DID_ADD_FIRM))
Container.registerStore('newFirmUser', storePrototype(FirmActions.Types.DID_ADD_FIRM_USER))
Container.registerStore('newLicense', storePrototype(FirmActions.Types.DID_ADD_LICENSE))
Container.registerStore('firmConfig', storePrototype(FirmActions.Types.DID_UPDATE_FIRM_CONFIG))
Container.registerStore('firmUserConfig', storePrototype(FirmActions.Types.GOT_FIRM_USER_CONFIG))


export default (
  <Route component={App}>
    <Redirect from='clients/:clientId' to='/pro/clients/:clientId' /> { /* for backwards rails compatibility */}
    <Route path='pro'> { /* awkward but because of the JSX limitation needing on top level container, it's the only we can accomplish top-level redirects... --BJK */}
      <Route component={MetaDataContainerWithUserConfigAndUser}>
        <Route path='quarterback-admin' component={QuarterbackAdmin}>
          <IndexRedirect to='clients' />
          <Route path='clients' component={QuarterbackClientSummariesContainer} />
          <Route path='clients/:clientSeatId' component={QuarterbackClientContainer} >
            <Route path='invite/:inviteSeatId' component={InviteClientModalContainer} />
            <Route path=':everplanId/category/:slug'>
              <Route path='item/:itemSlug' component={ProItemController}>
                <Route path='add-more/:id' component={addMore(CompoundResponseGroup)} />
                <Route path='edit/compound(/:groupId)' component={editItem(CompoundResponseGroup)} />
                <Route path='edit(/:groupId)' component={editItem(ItemForm)} />
                <Route path='new/compound(/:elementId)(/:groupId)' component={newItem(CompoundResponseGroup)} />
                <Route path='new(/:elementId)' component={newItem(ItemForm)} />
                <Route path='view(/:groupId)(/:elementId)' component={ViewItem} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route component={FirmContainer}>
          <IndexRedirect to='clients' />
          <Route path='clients' component={Clients}>
            <Route component={PickAdder}>
              <Route path='add_individual(/:userId/:clientSeatId)' component={AddIndividualClient} />
              <Route path='add_household' component={AddHouseholdClients} />
              <Route path='add_many' component = {AddBulkClients} />
              <Route path='confirm-add-many/:batchToken' component = {ConfirmAddBulkClients} />
            </Route>
            <Route path='bulk-actions' component={BulkSendOrResendController}>
              <Route path='select-advisor/:type' component={SelectAdvisorController} />
              <Route path='select-clients/:advisorSeatId/:type' component={PickRecipientsController} />
              <Route path='customize' component={CustomizeBulkClientInviteController} />
            </Route>
            <Route path='bulk_actions' component={LegacyBulkSendOrResendController}>
              <Route path='select-invite-advisor' component={SelectInviteAdvisor} />
              <Route path='select-reinvite-advisor' component={SelectReinviteAdvisor} />
              <Route path='select-clients/:advisorSeatId'>
                <Route path='draft' component={PickDraft} />
                <Route path='pending' component={PickPending} />
              </Route>
            </Route>
          </Route>
          <Route path='clients/:clientId' component={bindResources(Client, {name: 'client', type: 'item'}, Client.onBoundUpdate)} />{/* Essentially a redirect to new client detail view */}
          <Route path='clients/client_add' component={Clients} />
          <Route path='firm-admin' component={FirmAdmin}>
            <Route path='all-firms' component={FirmSummariesTableContainer} />
          </Route>
          <Route path='organization-admin' component={OrganizationAdmin}>
            <Route path='organizations' component={OrganizationsTableContainer} />
            <Route path='organizations/:organizationId' component={Organization}>
              <Route path='overview' component={Overview} />
              <Route path='settings' component={OrganizationConfig} />
            </Route>
          </Route>
          <Route path='quarterback-seat-admin' component={QuarterbackSeatAdmin}>
            <Route path='quarterback-seats' component={QuarterbackSeatsTableContainer} />
            <Route path='quarterback-seats/:quarterbackSeatId' component={QuarterbackSeat} />
          </Route>
          <Route path='household/:clientSeatId' component={HouseholdContainer}>
            <Route component={InviteClientModalContainer} path='invite/:inviteSeatId' />
            {/* START CM write access routes */}
            <Redirect from=':everplanId/category/old-age' to=':everplanId/category/aging' />
            <Redirect from=':everplanId/category/remembering-me' to=":/everplanId/category/after-i'm-gone" />
            <Redirect from=':everplanId/category/when-i-go' to=":everplanId/category/after-i'm-gone" />
            <Redirect from=':everplanId/category/about-me' to={':everplanId/category/personal-info-and-ids'} />
            <Redirect from=':everplanId/category/health-and-medical' to=':everplanId/category/health' />
            <Route path=':everplanId/category/:slug'>
              <Route component={ProItemController} path='item/:itemSlug'>
                <Route component={addMore(CompoundResponseGroup)} path='add-more/:id' />
                <Route component={editItem(CompoundResponseGroup)} path='edit/compound(/:groupId)' />
                <Route component={editItem(ItemForm)} path='edit(/:groupId)' />
                <Route component={newItem(CompoundResponseGroup)} path='new/compound(/:elementId)(/:groupId)' />
                <Route component={newItem(ItemForm)} path='new(/:elementId)' />
                <Route component={ViewItem} path='view(/:groupId)(/:elementId)' />
              </Route>
            </Route>
            {/* END CM write access routes */}
          </Route>
          <Route path='firm/:firmId' component={Firm}>
            <Route path='licenses' component={FirmLicenses} />
            <Route path='settings' component={FirmConfigController} />
            <Route path='users' component={bindResources(FirmUsers, {id: 'firmId', name: 'firm_users', type: 'item'})} />
            <Route path='user/:firmUserSeatId' component={FirmUser}>
              <IndexRedirect to='account-info' />
              <Route path='account-info' component={AccountInfo} />
              <Route path='contact-card' component={ContactCard} />
              <Route path='invitation-message' component={InvitationMessage} />
              <Route path='integrations' component={Integrations} />
            </Route>
          </Route>
          <Route path='oauth/authorizations' component={Authorizations} />
          <Route path='oauth/permissions' component={Permissions} />
        </Route>
      </Route>
      <Route path='confirm_invite/:inviteId' component={FirmUserConfirm} />
    </Route>
    <Route path='quarterback/confirm_invite/:invitationToken' component={QuarterbackUserConfirm} />
  </Route>
)
