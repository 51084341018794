import {Map} from 'immutable'
import PropTypes from 'prop-types'

import classnames from 'classnames'
import {ControlButton} from '../../../../../shared_components/ButtonTypes'
import Modals from '../../../../../shared_components/core/modals/Modals'
import RemoveFromPro from './RemoveFromPro'

import './removeClientModal.scss'

const RemoveClientModal = props => (
  <Modals.ButtonPopUpModalLarge
    launcher={
      <ControlButton
        className={classnames('remove-client-button', {disabled: props.disabled})}
        buttonText={props.buttonText}
        faClass='fa-trash-o'
        disabled={props.disabled}
      />
    }>
    <h2>Are you sure you want to remove {props.fullName}?</h2>
    <RemoveFromPro firstName={props.firstName} status={props.clientSeat.get('status')} {...props} />
  </Modals.ButtonPopUpModalLarge>
)

RemoveClientModal.propTypes = {
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  firstName: PropTypes.string,
  fullName: PropTypes.string,
  clientSeat: PropTypes.instanceOf(Map),
  owner: PropTypes.instanceOf(Map)
}

export default RemoveClientModal
