import PropTypes from 'prop-types'

import {Map} from 'immutable'
import {useEffect, useMemo, useState} from 'react'
import {connect} from 'react-redux'
import {deleteResource, readEndpoint, updateResource} from 'redux-json-api'

import {
  useNotificationContext
} from '../../../../../shared_components/notifications/NotificationContext'

import Loader from '../../../../../shared_components/NewLoader'
import QuarterbackSeatHeader from '../QuarterbackSeatHeader'
import SendQuarterbackInviteElement from './SendQuarterbackInviteElement'
import routerUtils from '../../../../../lib/routerUtils'

import {humanizedFullName} from '../../../../../lib/nameTools'
import {findRawResourceById} from '../../../../../lib/plan_data/userData'

import './quarterbackSeat.scss'

const QuarterbackSeat = props => {
  const [isProcessing, setIsProcessing] = useState(false)

  const quarterbackSeatStatus = useMemo(
    () => props.quarterbackSeat.get('status'),
    [props.quarterbackSeat.get('status')]
  )

  const {alwaysNotify} = useNotificationContext()

  useEffect(() => {
    props.readEndpoint(`quarterback-seats/${props.params.quarterbackSeatId}`)
  }, [props.params.quarterbackSeatId])

  const handleRemoveQuarterbackSeatClick = () => {
    setIsProcessing(true)

    const payload = {
      type: 'quarterback-seats',
      id: props.quarterbackSeat.get('id')
    }

    props.deleteResource(payload).then(() => {
      setIsProcessing(false)

      alwaysNotify.longSuccess(
        `${humanizedFullName(props.quarterbackSeat)} has been removed.`
      )

      routerUtils.push('pro/quarterback-seat-admin/quarterback-seats')
    })
  }

  return (
    <Loader loading={props.loading}>
      <div className='quarterback-seat'>
        <QuarterbackSeatHeader
          className='column'
          headerText={humanizedFullName(props.quarterbackSeat)}>
          <div className='box'>
            <div className='status-section section'>
              <div className='container'>
                <div className='label'>Status</div>
                <div className='status-value'>{props.quarterbackSeat.get('status')}</div>
              </div>
              {
                quarterbackSeatStatus !== 'active' &&
                <SendQuarterbackInviteElement
                  isProcessing={isProcessing}
                  quarterbackSeat={props.quarterbackSeat}
                  readEndpoint={props.readEndpoint}
                  updateResource={props.updateResource}
                />
              }
            </div>
            <div className='section'>
              <div className='container'>
                <div className='label'>Name:</div>
                <div>{humanizedFullName(props.quarterbackSeat)}</div>
              </div>
              <div className='container'>
                <div className='label'>Email:</div>
                <div>{props.quarterbackSeat.get('email')}</div>
              </div>
              <div className='container'>
                <div className='label'>Role:</div>
                <div>Quarterback</div>
              </div>
              <div className='container'>
                <div className='label'>External ID:</div>
                <div>{props.quarterbackSeat.get('id')}</div>
              </div>
            </div>
            <a
              className='remove-user-link'
              onClick={isProcessing ? null : handleRemoveQuarterbackSeatClick}>
              Remove user
            </a>
          </div>
        </QuarterbackSeatHeader>
      </div>
    </Loader>
  )
}

const mapStateToProps = ({api}, ownProps) => {
  const quarterbackSeat = findRawResourceById({
    rawResource: api['quarterback-seats'],
    id: ownProps.params.quarterbackSeatId
  })

  return {quarterbackSeat, loading: quarterbackSeat.isEmpty()}
}

QuarterbackSeat.propTypes = {
  deleteResource: PropTypes.func,
  params: PropTypes.shape({quarterbackSeatId: PropTypes.string}),
  quarterbackSeat: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func,
  updateResource: PropTypes.func
}

export default connect(
  mapStateToProps,
  {deleteResource, readEndpoint, updateResource}
)(QuarterbackSeat)
