/* eslint-disable no-console */
import PropTypes from 'prop-types'

import {useState, useEffect, useCallback} from 'react'
import {withRouter} from 'react-router'

import {Typography} from '@mui/material'

import FuneralHomeClientSummariesTable from './FuneralHomeClientSummariesTable'
import routerUtils from '../../../../../lib/routerUtils'

import {
  pageNumberAndSize,
  sortValue
} from '../../../../../lib/summariesTableTools'

import {capitalize, cleanApiResponseData, debounce} from '../../../../../lib/tools'

import './funeralHomeClientSummariesTableContainer.scss'

export const FuneralHomeClientSummariesTableContainer = props => {
  const [filtered, setFiltered] = useState([])
  const [isFiltering, setIsFiltering] = useState(false)
  const [loadingClients, setLoadingClients] = useState(true)
  const [clientSummaries, setClientSummaries] = useState([])
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [pages, setPages] = useState(-1)
  const [recordCount, setRecordCount] = useState(0)
  const [sorted, setSorted] = useState([])
  const [uniqueFilterValues, setUniqueFilterValues] = useState({afpNames: []})

  const buildFilterQuery = useCallback(tableStateFiltered => {
    if (!tableStateFiltered || !tableStateFiltered.length) return ''

    return tableStateFiltered.reduce((acc, filter) => {
      // eslint-disable-next-line no-undefined
      if (filter.value === null || filter.value === undefined ||
          (Array.isArray(filter.value) && filter.value.length === 0) ||
          filter.value === '')
        return acc


      // Handle array values for checkbox filters
      if (Array.isArray(filter.value) && filter.value.length > 0)
        return `${acc}&filter[${filter.id}]=${encodeURIComponent(filter.value.join(','))}`


      return `${acc}&filter[${filter.id}]=${encodeURIComponent(filter.value)}`
    }, '')
  }, [])

  const readEndpointQuery = useCallback(tableState => {
    const baseQuery = tableState ?
      `${pageNumberAndSize(tableState)}${sortValue(tableState.sorted)}${buildFilterQuery(tableState.filtered)}` :
      ''

    return baseQuery
  }, [])

  const fetchData = useCallback(
    debounce(tableState => {
      setLoadingClients(true)
      setIsFiltering(tableState.filtered?.length > 0)

      props.readEndpoint(`client-summaries?${readEndpointQuery(tableState)}`)
        .then(response => {
          const clients = cleanApiResponseData(response.body.data)

          setClientSummaries(clients)
          setLoadingClients(false)
          setPages(response.body.meta['page-count'])
          setRecordCount(response.body.meta['record-count'])
        })
        .catch(error => {
          console.error('Error fetching clients:', error)
          setLoadingClients(false)
        })
    }, 500),
    [props.readEndpoint]
  )

  useEffect(() => {
    props.readEndpoint('client-summaries/unique-dropdown-values')
      .then(response => {
        const data = response.body.data.attributes

        setUniqueFilterValues({afpNames: data['afp-names'] || []})
      })
      .catch(error => {
        console.error('Error fetching unique filter values:', error)
      })
  }, [props.readEndpoint])

  const statusFilter = status => (
    status && status !== 'all' ?
      [{id: 'status', value: capitalize(status)}] :
      []
  )
  useEffect(() => {
    if (props.location.query.status) {
      setFiltered(statusFilter(props.location.query.status))
      setPage(0)

      routerUtils.push('pro/clients')
    }
  }, [props.location.query.status])

  useEffect(() => {
    const tableState = {
      filtered,
      page,
      pageSize,
      sorted
    }

    fetchData(tableState)
  }, [fetchData, filtered, page, pageSize, sorted])

  const handlePageSizeChange = newPageSize => {
    setPageSize(newPageSize)
    setPage(0)
  }

  const handleRowClick = clientSeatId => {
    routerUtils.push(`pro/clients/${clientSeatId}`)
  }

  return (
    <div className='funeral-home-client-summaries-table-container'>
      <Typography
        className='heading'
        data-testid='heading'
        variant='h6'>
        Families
      </Typography>

      <FuneralHomeClientSummariesTable
        clientSummaries={clientSummaries}
        isFiltering={isFiltering}
        key={`${page}-${pageSize}`}
        loadingClients={loadingClients}
        filtered={filtered}
        onFetchData={fetchData}
        onFilteredChange={newFilters => setFiltered(newFilters)}
        onPageChange={newPage => setPage(newPage)}
        onPageSizeChange={newPageSize => handlePageSizeChange(newPageSize)}
        onRowClick={handleRowClick}
        onSortedChange={newSorted => setSorted(newSorted)}
        page={page}
        pageSize={pageSize}
        pages={pages}
        recordCount={recordCount}
        uniqueFilterValues={uniqueFilterValues}
      />
    </div>
  )
}

FuneralHomeClientSummariesTableContainer.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      status: PropTypes.string
    })
  }),
  readEndpoint: PropTypes.func.isRequired
}

export default withRouter(FuneralHomeClientSummariesTableContainer)
