/* eslint-disable no-undefined */
import {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  List,
  ListItem,
  Popover,
  Box,
  Typography,
  TextField,
  IconButton,
  Button
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CloseIcon from '@mui/icons-material/Close'
import './checkboxFilter.scss'

const CheckboxFilter = ({filter, onChange, options, column}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedValues, setSelectedValues] = useState(filter && filter.value ? filter.value : [])
  const [hoveredItem, setHoveredItem] = useState(null)
  const [tempSelectedValues, setTempSelectedValues] = useState([])

  useEffect(() => {
    if (filter && filter.value)
      setSelectedValues(filter.value)
  }, [filter])

  // When opening the dropdown, initialize temp values with current selection
  useEffect(() => {
    if (anchorEl)
      setTempSelectedValues([...selectedValues])
  }, [anchorEl, selectedValues])

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleApply = () => {
    if (tempSelectedValues.length === 0)
      onChange(undefined)
    else
      onChange(tempSelectedValues)

    setSelectedValues(tempSelectedValues)
    setAnchorEl(null)
  }

  const handleClear = event => {
    event.stopPropagation()
    setTempSelectedValues([])
    setSelectedValues([])
    onChange(undefined)
    setAnchorEl(null)
  }

  const handleChange = value => {
    setTempSelectedValues(prevValues => {
      if (prevValues.includes(value))
        return prevValues.filter(val => val !== value)
      else
        return [...prevValues, value]
    })
  }

  const handleSelectAll = () => {
    if (tempSelectedValues.length === options.length) {
      setTempSelectedValues([])
    } else {
      const allValues = options.map(option => option.id || option)
      setTempSelectedValues(allValues)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'checkbox-filter-dropdown' : undefined

  // Format display text for the dropdown
  const getDisplayText = () => {
    if (selectedValues.length === 0)
      return `All ${column.Header}s`
    else
      return `${selectedValues.length} ${column.Header}${selectedValues.length > 1 ? 's' : ''} selected`
  }

  return (
    <div className='checkbox-filter-dropdown'>
      <Box className='dropdown-trigger' onClick={handleClick}>
        <TextField
          variant='outlined'
          size='small'
          value={getDisplayText()}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <IconButton size='small' edge='end'>
                <KeyboardArrowDownIcon />
              </IconButton>
            )
          }}
        />
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          className: 'filter-popover'
        }}>
        <Box className='filter-header'>
          <Checkbox
            checked={tempSelectedValues.length === options.length && options.length > 0}
            indeterminate={tempSelectedValues.length > 0 && tempSelectedValues.length < options.length}
            onChange={handleSelectAll}
            size='small'
          />
          <IconButton size='small' onClick={handleClear} className='clear-button'>
            <CloseIcon fontSize='small' />
          </IconButton>
        </Box>

        <List className='filter-option-list'>
          {options.map(option => {
            const value = option.id || option
            const label = option.name || option
            const isSelected = tempSelectedValues.includes(value)
            const isHovered = hoveredItem === value

            return (
              <ListItem
                key={value}
                className={`filter-option ${isSelected ? 'selected' : ''} ${isHovered ? 'hovered' : ''}`}
                onMouseEnter={() => setHoveredItem(value)}
                onMouseLeave={() => setHoveredItem(null)}
                onClick={() => handleChange(value)}
                disablePadding>
                <div className='option-content'>
                  <Checkbox
                    checked={isSelected}
                    size='small'
                    onChange={() => handleChange(value)}
                    onClick={e => e.stopPropagation()}
                  />
                  <Typography variant='body2'>{label}</Typography>
                </div>
              </ListItem>
            )
          })}
        </List>

        <div className='filter-actions'>
          <Button
            variant='contained'
            color='primary'
            size='medium'
            onClick={handleApply}
            fullWidth
            className='apply-button'>
            Apply
          </Button>
        </div>
      </Popover>
    </div>
  )
}

CheckboxFilter.propTypes = {
  filter: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  column: PropTypes.object.isRequired
}

export default CheckboxFilter
