import PropTypes from 'prop-types'

import './quarterbackSeatHeader.scss'

const QuarterbackSeatHeader = props => (
  <div
    className='quarterback-seat-header'
    data-testid='quarterback-seat-header'>
    <div>
      <h1 className='quarterback-seat-header__title'>{props.headerText}</h1>
    </div>
    {props.children}
  </div>
)

QuarterbackSeatHeader.propTypes = {
  headerText: PropTypes.string
}

export default QuarterbackSeatHeader
