import PropTypes from 'prop-types'

const QuarterbackHeading = props => (
  <span
    className='quarterback-heading row-wrapper-sb'
    data-testid='quarterback-heading'>
    <h1>{props.name}</h1>
    <span>
      <strong>Funeral Home:</strong> {props.firmName}
    </span>
  </span>
)

QuarterbackHeading.propTypes = {
  name: PropTypes.string,
  firmName: PropTypes.string
}

export default QuarterbackHeading
