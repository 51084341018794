/* eslint-disable no-console */
import {useState, useEffect, useCallback} from 'react'
import {connect} from 'react-redux'
import {Map, List} from 'immutable'
import PropTypes from 'prop-types'
import {readEndpoint} from 'redux-json-api'
import {Typography} from '@mui/material'

import QuarterbackClientSummariesTable from './QuarterbackClientSummariesTable'
import routerUtils from '../../../../../lib/routerUtils'

import {cleanApiResponseData} from '../../../../../lib/tools'
import {
  pageNumberAndSize,
  sortValue
} from '../../../../../lib/summariesTableTools'

import './quarterbackClientSummariesContainer.scss'

export const QuarterbackClientSummariesContainer = props => {
  const [isFiltering, setIsFiltering] = useState(false)
  const [loadingClients, setLoadingClients] = useState(true)
  const [clientSummaries, setClientSummaries] = useState([])
  const [pages, setPages] = useState(-1)
  const [recordCount, setRecordCount] = useState(0)
  const [uniqueFilterValues, setUniqueFilterValues] = useState({
    firmNames: [],
    afpNames: []
  })

  const buildFilterQuery = useCallback(filtered => {
    if (!filtered || !filtered.length) return ''

    return filtered.reduce((acc, filter) => {
      // eslint-disable-next-line no-undefined
      if (filter.value === null || filter.value === undefined ||
          (Array.isArray(filter.value) && filter.value.length === 0) ||
          filter.value === '')
        return acc


      // Handle array values for checkbox filters
      if (Array.isArray(filter.value) && filter.value.length > 0)
        return `${acc}&filter[${filter.id}]=${encodeURIComponent(filter.value.join(','))}`


      return `${acc}&filter[${filter.id}]=${encodeURIComponent(filter.value)}`
    }, '')
  }, [])

  const readEndpointQuery = useCallback(tableState => {
    const baseQuery = tableState ?
      `${pageNumberAndSize(tableState)}${sortValue(tableState.sorted)}${buildFilterQuery(tableState.filtered)}` :
      ''

    return baseQuery
  }, [buildFilterQuery])

  const fetchClients = useCallback(tableState => {
    setLoadingClients(true)
    setIsFiltering(tableState?.filtered.length > 0)

    props.readEndpoint(`client-summaries?${readEndpointQuery(tableState)}`)
      .then(response => {
        const clients = cleanApiResponseData(response.body.data)

        setLoadingClients(false)
        setClientSummaries(clients)
        setPages(response.body.meta['page-count'])
        setRecordCount(response.body.meta['record-count'])
      })
      .catch(error => {
        console.error('Error fetching clients:', error)
        setLoadingClients(false)
      })
  }, [props.readEndpoint, readEndpointQuery])

  const handleRowClick = clientSeatId => {
    routerUtils.push(`pro/quarterback-admin/clients/${clientSeatId}`)
  }

  useEffect(() => {
    props.readEndpoint('client-summaries/unique-dropdown-values')
      .then(response => {
        const data = response.body.data.attributes
        setUniqueFilterValues({
          firmNames: data['firm-names'] || [],
          afpNames: data['afp-names'] || []
        })
      })
      .catch(error => {
        console.error('Error fetching unique filter values:', error)
      })
  }, [props.readEndpoint])

  useEffect(() => {
    fetchClients()
  }, [fetchClients])

  return (
    <div className='quarterback-clients-summaries-container'>
      <Typography
        className='heading'
        data-testid='heading'
        variant='h6'>
        Families
      </Typography>

      <QuarterbackClientSummariesTable
        clientSummaries={clientSummaries}
        isFiltering={isFiltering}
        loadingClients={loadingClients}
        pages={pages}
        recordCount={recordCount}
        onFetchData={fetchClients}
        onRowClick={handleRowClick}
        uniqueFilterValues={uniqueFilterValues}
      />
    </div>
  )
}

QuarterbackClientSummariesContainer.propTypes = {
  addedClients: PropTypes.instanceOf(List),
  bulkClientInvites: PropTypes.instanceOf(List),
  userConfig: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func.isRequired,
  createResource: PropTypes.func,
  onRowClick: PropTypes.func
}

export default connect(null, {readEndpoint})(QuarterbackClientSummariesContainer)
