import {useCallback, useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {updateResource, readEndpoint} from 'redux-json-api'
import {useForm, FormProvider} from 'react-hook-form'
import PropTypes from 'prop-types'
import {Stack} from '@mui/material'

import Button from '../../../../shared_components/mui_base_components/button/Button'
import ButtonGroup from '../../../../shared_components/core/buttons/ButtonGroup'
import Loader from '../../../../shared_components/NewLoader'
import SessionApi from '../../../../auth/src/web/SessionApi'
import PasswordWithConfirmation from '../../../../shared_components/forms/v3/PasswordWithConfirmation'
import ServiceTerms from '../../../../shared_components/forms/v3/ServiceTerms'
import {goToInterstitials} from '../../../../lib/interstitialTools'
import routerUtils from '../../../../lib/routerUtils'

import './quarterbackUserConfirm.scss'

export const QuarterbackUserConfirm = props => {
  const [processing, setProcessing] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [invite, setInvite] = useState(null)
  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      password: '',
      password_confirmation: '',
      agreement: false
    }
  })

  useEffect(() => {
    const fetchInviteDetails = async () => {
      try {
        const response = await props.readEndpoint(`quarterback-invites/by-token/${props.params.invitationToken}`)
        setInvite(response.body)
        setLoading(false)
      } catch (err) {
        setError(err)
        routerUtils.push('/auth/invalid_token/quarterback_invite')
      }
    }

    fetchInviteDetails()
  }, [props.params.invitationToken, props.readEndpoint])

  const submit = useCallback(formData => {
    setProcessing(true)

    props.updateResource({
      type: 'quarterback-invites',
      id: invite.data.id,
      attributes: {
        password: formData.password,
        'password-confirmation': formData.password_confirmation,
        'agreement-accepted': formData.agreement,
        'invitation-token': props.params.invitationToken
      }
    }).then(response => {
      SessionApi._setToken(response.data.attributes['session-token'])
      SessionApi.fetchCurrentUser()
      goToInterstitials()
    }).catch(() => {
      setProcessing(false)
    })
  }, [props.params.invitationToken, invite])

  if (loading)
    return <Loader loading={true} />


  if (error)
    return null // We're redirecting, no need to show anything

  const firstName = invite?.data?.attributes?.['first-name'] || ''

  return (
    <div className='quarterback-user-confirm'>
      <div className='content'>
        <h1 className='heading-1'>Welcome, {firstName}!</h1>
        <h3 className='heading-3'>Create your password to get started.</h3>
        <FormProvider {...methods}>
          <Stack spacing={3}>
            <PasswordWithConfirmation />
            <ServiceTerms />
            <ButtonGroup>
              <Button
                onClick={methods.handleSubmit(submit)}
                loading={processing}
                className='confirm-button'
                fullWidth>
                Confirm My Account
              </Button>
            </ButtonGroup>
          </Stack>
        </FormProvider>
      </div>
    </div>
  )
}

QuarterbackUserConfirm.propTypes = {
  updateResource: PropTypes.func.isRequired,
  readEndpoint: PropTypes.func.isRequired,
  invite: PropTypes.shape({
    data: PropTypes.shape({
      attributes: PropTypes.shape({
        first_name: PropTypes.string
      })
    })
  }),
  loading: PropTypes.bool,
  params: PropTypes.shape({
    invitationToken: PropTypes.string.isRequired
  }).isRequired
}

export default connect(null, {updateResource, readEndpoint})(QuarterbackUserConfirm)
