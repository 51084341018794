import {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {readEndpoint} from 'redux-json-api'

import Loader from '../../../shared_components/NewLoader'

import {cleanResponse} from '../../../dashboard/src/components/corp_max/redux/apiHelper'
import {currentUserConfig} from '../../../lib/userTools'
import {findResourceById, findRawResourceByAttributeId} from '../../../lib/plan_data/userData'
import routerUtils from '../../../lib/routerUtils'
import ProCsvDownloadStatus from './pro_csv_report/ProCsvDownloadStatus'

class FirmContainer extends Component {
  componentDidMount() {
    const userConfig = this.props.userConfig
    this.props.readEndpoint(`firms/${userConfig.get('firm-id')}?include=client-seats,cobranding,firm-config,disclosure,firm-seats,organization.organization-config`)
    this.props.readEndpoint(`users/${userConfig.get('id')}?include=firm-user-config`)
  }

  render() {
    if (this.props.loading) {
      return <Loader loading={true} />
    } else {
      return (
        <Fragment>
          {routerUtils.childrenWithProps(this.props.children, {
            firmConfig: this.props.firmConfig
          })}
          <ProCsvDownloadStatus />
        </Fragment>
      )
    }
  }
}

FirmContainer.propTypes = {
  readEndpoint: PropTypes.func,
  firm: PropTypes.instanceOf(Map),
  firmConfig: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = state => {
  const userConfig = currentUserConfig(state.api)
  const firmId = userConfig.get('firm-id')
  const firm = findResourceById({
    id: firmId,
    resourceList: cleanResponse(state.api.firms)
  })
  const firmConfig = findRawResourceByAttributeId({
    rawResource: state.api['firm-configs'],
    attribute: 'firm-id',
    id: firmId
  })

  return {
    loading: firm.isEmpty(),
    firmConfig,
    userConfig
  }
}

export default connect(mapStateToProps, {readEndpoint})(FirmContainer)
