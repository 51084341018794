import PropTypes from 'prop-types'

import {useState} from 'react'
import {connect} from 'react-redux'
import {createResource} from 'redux-json-api'

import {
  useNotificationContext
} from '../../../../../shared_components/notifications/NotificationContext'

import QuarterbackSeatHeader from '../QuarterbackSeatHeader'
import QuarterbackSeatForm from '../QuarterbackSeatForm'
import routerUtils from '../../../../../lib/routerUtils'

import './quarterbackSeatsTableHeader.scss'

export const QuarterbackSeatsTableHeader = props => {
  const {alwaysNotify} = useNotificationContext()

  const [isProcessing, setIsProcessing] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const toggleModal = event => {
    if (event) event.preventDefault()

    setIsModalVisible(!isModalVisible)
  }

  const addQuarterbackSeat = data => {
    setIsProcessing(true)

    const payload = {
      type: 'quarterback-seats',
      attributes: {
        'first-name': data.getIn(['name', 'value']).get('first-name'),
        'last-name': data.getIn(['name', 'value']).get('last-name'),
        email: data.getIn(['email', 'value'])
      }
    }

    props.createResource(payload).then(response => {
      setIsProcessing(false)

      toggleModal()

      routerUtils.push(
        `pro/quarterback-seat-admin/quarterback-seats/${response.data.id}`
      )
    }).catch(error => {
      setIsProcessing(false)

      if (error)
        alwaysNotify.shortError('Please add a unique name.')
    })
  }

  return (
    <span
      className='quarterback-seats-table-header'
      data-testid='quarterback-seats-table-header'>
      <QuarterbackSeatHeader headerText='Quarterbacks'>
        <button
          className='add-quarterback-seat btn btn-primary'
          onClick={toggleModal}>
          Add Quarterback
        </button>
      </QuarterbackSeatHeader>
      <QuarterbackSeatForm
        actionText='Add'
        closeModal={toggleModal}
        modalHeaderText='Add Quarterback'
        isProcessing={isProcessing}
        isModalVisible={isModalVisible}
        submit={addQuarterbackSeat}
      />
    </span>
  )
}

QuarterbackSeatsTableHeader.propTypes = {
  createResource: PropTypes.func,
  loading: PropTypes.bool
}

export default connect(null, {createResource})(QuarterbackSeatsTableHeader)
