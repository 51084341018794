import PropTypes from 'prop-types'
import {useFormContext, Controller} from 'react-hook-form'
import {Checkbox, FormControlLabel, FormHelperText, Link} from '@mui/material'

import './serviceTerms.scss'

const ServiceTerms = ({
  name = 'agreement'
}) => {
  const {control, formState: {errors}} = useFormContext()

  return (
    <div className='service-terms'>
      <Controller
        name={name}
        control={control}
        defaultValue={false}
        rules={{
          required: 'You must agree to the terms of service and privacy policy.'
        }}
        render={({field}) => (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  checked={field.value}
                  size='small'
                />
              }
              label={
                <span className='terms-text'>
                  I agree to the <Link href={`${CONTENT_HOST}/terms-conditions`} target='_blank' rel='noopener noreferrer'>Terms of Service</Link> and <Link href={`${CONTENT_HOST}/privacy-policy`} target='_blank' rel='noopener noreferrer'>Privacy Policy</Link>
                </span>
              }
            />
            {errors[name] && (
              <FormHelperText error>
                {errors[name].message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </div>
  )
}

ServiceTerms.propTypes = {
  name: PropTypes.string
}

export default ServiceTerms
