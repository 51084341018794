import PropTypes from 'prop-types'
import {Component} from 'react'

import * as ButtonTypes from '../../../../shared_components/ButtonTypes'
import Forms from '../../../../shared_components/forms'
import Modal from '../../../../shared_components/Modal'
import NewLoader from '../../../../shared_components/NewLoader'
import States from '../../../../lib/us-states-data.json'

export default class FirmForm extends Component {
  constructor() {
    super()

    this.submit = this.submit.bind(this)
  }

  submit() {
    if (this.refs.form.validate().length === 0)
      this.props.submit(this.refs.form.formData())
  }

  render() {
    return (
      <Modal closer={this.props.toggleModal} visible={this.props.showModal}>
        <div className='modal-header'>{this.props.modalheaderText}</div>
        <NewLoader loading={this.props.loadingOrganizations}>
          <Forms.Form ref='form' errors={[{name: 'firm_name', errors: this.props.errors}]}>
            <Forms.Input
              name='firm_name'
              label='Firm Name:'
              validator={Forms.Validators.required}
              defaultValue={this.props.firmName}
            />
            <Forms.Select
              defaultValue={this.props.organizationId}
              name='organization_id'
              label='Organization'
              list={this.props.organizations.data.map(organization => ({key: organization.id, value: organization.name}))}
              placeholder='No Organization'
            />
            <Forms.Select
              defaultValue={this.props.firmType || 'firm'}
              name='firm_type'
              label='Firm Type'
              list={[{key: 'firm', value: 'Firm'}, {key: 'funeral_home', value: 'Funeral Home'}]}
              validator={Forms.Validators.required}
            />
            <Forms.Input
              name='external_id'
              label='External ID'
              defaultValue={this.props.externalId}
            />
            <Forms.Input
              name='billing_address1'
              label='Address Line 1'
              defaultValue={this.props.billingAddress1}
            />
            <Forms.Input
              name='billing_address2'
              label='Address Line 2'
              defaultValue={this.props.billingAddress2}
            />
            <Forms.Input
              name='billing_city'
              label='City'
              defaultValue={this.props.billingCity}
            />
            <Forms.Select
              defaultValue={this.props.billingState}
              name='billing_state'
              label='State'
              list={States.map(state => ({key: state, value: state}))}
            />
            <Forms.Input
              name='billing_zip'
              label='Zip'
              defaultValue={this.props.billingZip}
            />
            <Forms.Input
              name='billing_phone'
              label='Phone'
              defaultValue={this.props.billingPhone}
            />
            <Forms.Input
              name='website'
              label='Website'
              defaultValue={this.props.website}
            />
            <Forms.Checkbox
              defaultValue={this.props.internal}
              label='Internal test firm'
              name='internal'
              className='internal'
              value={this.props.internal}
            />
          </Forms.Form>
        </NewLoader>
        <div className='button-group'>
          <ButtonTypes.Cancel onClick={this.props.closeModal} />
          <ButtonTypes.Continue loading={this.props.processing} onClick={this.submit}>{this.props.actionText}</ButtonTypes.Continue>
        </div>
      </Modal>
    )
  }
}

FirmForm.propTypes = {
  actionText: PropTypes.string,
  closeModal: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
  firmName: PropTypes.string,
  modalheaderText: PropTypes.string,
  internal: PropTypes.bool,
  loadingOrganizations: PropTypes.bool,
  organizations: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object)
  }),
  organizationId: PropTypes.string,
  processing: PropTypes.bool,
  showModal: PropTypes.bool,
  submit: PropTypes.func,
  toggleModal: PropTypes.func
}

FirmForm.defaultProps = {
  modalheaderText: 'Add Firm'
}
