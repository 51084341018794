import PropTypes from 'prop-types'
import React from 'react'

import SummariesTable from '../../shared_components/summaries_table/SummariesTable'
import SelectFilter from '../../shared_components/SelectFilter'
import CheckboxFilter from '../../shared_components/CheckboxFilter'

import {formatDate} from '../../../../../lib/dateTools'

const parseStatusForDisplay = statusString => {
  if (!statusString) return []

  const statuses = statusString.split(',').map(status => status.trim())

  return statuses.map(status => ({text: status, className: `${status.toLowerCase().replace(' ', '-')}`}))
}

import './funeralHomeClientSummariesTable.scss'

const FuneralHomeClientSummariesTable = props => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      id: 'name',
      minWidth: 160,
      filterable: true
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: 'status',
      minWidth: 110,
      Cell: ({value}) => <StatusCell value={value} />,
      filterable: true,
      Filter: columnProps => (
        <SelectFilter
          {...columnProps}
          options={[
            {name: 'Active', id: 'Active'},
            {name: 'Pending', id: 'Pending'},
            {name: 'Draft', id: 'Draft'},
            {name: 'Declined', id: 'Declined'},
            {name: 'Household Declined', id: 'Household Declined'},
            {name: 'Deceased', id: 'Deceased'},
            {name: 'Requesting Approval', id: 'Requesting Approval'}
          ]}
        />
      )
    },
    {
      id: 'afp-name',
      Header: 'Advance Planner',
      accessor: 'afp-name',
      minWidth: 130,
      filterable: true,
      Filter: columnProps => (
        <CheckboxFilter
          {...columnProps}
          options={props.uniqueFilterValues.afpNames || []}
          column={columnProps.column}
        />
      )
    },
    {
      id: 'created-at',
      Header: 'Date Added',
      accessor: client => formatDate(client['created-at']),
      minWidth: 110
    },
    {
      id: 'last-login-date',
      Header: 'Last Login',
      accessor: client => formatDate(client['last-login-date']),
      minWidth: 110
    }
  ]

  const noClientsMessage = () => {
    if (props.loadingClients) return null

    if (props.isFiltering) {
      return (
        <div className='no-clients-message'><div>No matching results</div></div>
      )
    } else {
      return (
        <div className='no-clients-message welcome-message'>
          <h1>Welcome to Everplans Professional!</h1>
          <div>Funeral home customers will appear here once they are added.</div>
        </div>
      )
    }
  }

  return (
    <SummariesTable
      {...props}
      className='funeral-home-client-summaries-table'
      data={props.clientSummaries}
      dataTestid='funeral-home-client-summaries-table'
      defaultSorted={[{id: 'created-at', desc: true}]}
      columns={columns}
      loading={props.loadingClients}
      minRows={6}
      NoDataComponent={noClientsMessage}
      onFetchData={props.onFetchData}
    />
  )
}

FuneralHomeClientSummariesTable.propTypes = {
  clientSummaries: PropTypes.array,
  isFiltering: PropTypes.bool,
  loadingClients: PropTypes.bool,
  onFetchData: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  pages: PropTypes.number,
  uniqueFilterValues: PropTypes.shape({
    afpNames: PropTypes.array
  }),
  value: PropTypes.string
}

const StatusCell = ({value}) => {
  if (!value) return null
  const statusParts = parseStatusForDisplay(value)

  return (
    <span>
      {statusParts.map((status, index) => (
        <React.Fragment key={index}>
          {index > 0 && <span>, </span>}
          <span className={status.className}>{status.text}</span>
        </React.Fragment>
      ))}
    </span>
  )
}

StatusCell.propTypes = {
  value: PropTypes.string
}

export default FuneralHomeClientSummariesTable
