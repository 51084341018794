import {addPossessive} from './tools'

/**
 * Returns the notification message to be displayed after quarterback invitation is sent based on the quarterback's status
 * @param {Map} quarterbackSeat
 * @returns {String}
 */
const notificationMessage = ({quarterbackSeat}) => {
  switch (quarterbackSeat.get('status')) {
    case 'pending':
      return `${addPossessive(`${quarterbackSeat.get('first-name')} ${quarterbackSeat.get('last-name')}`)} invitation has been resent.`
    default:
      return `${quarterbackSeat.get('first-name')} ${quarterbackSeat.get('last-name')}  has been invited. ${addPossessive(quarterbackSeat.get('first-name'))} status is PENDING until ${quarterbackSeat.get('first-name')} accepts the invitation.`
  }
}

/**
 * Triggers the display of toast notifications
 * @param {Map} quarterbackSeat
 * @returns {void}
 */
export const notifyAfterSend = ({alwaysNotify, quarterbackSeat}) => {
  const message = notificationMessage({quarterbackSeat})

  if (quarterbackSeat.get('status') === 'pending')
    alwaysNotify.shortSuccess(message)
  else
    alwaysNotify.longSuccess(message)
}
