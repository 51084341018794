/** @class Status
 *
 * @desc
 * Information about the status of an everplan owner
 *
 * @todo move everything to v2 resources
 *
 */

import classnames from 'classnames'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {clientCurrentStatus} from '../../shared_helpers/clientCurrentStatus'

const Status = props => {
  const clientIsDeceased = props.client.deceased_at
  const seatStatus = props.clientSeat.get('status')

  const statusClass = classnames({
    active: !clientIsDeceased && seatStatus === 'Active',
    pending: !clientIsDeceased && seatStatus === 'Pending',
    'requesting-approval': !clientIsDeceased && seatStatus === 'Requesting Approval'
  })

  return (
    <div className='user-status'>
      <p>Status:
        <span className={statusClass}> {clientCurrentStatus(clientIsDeceased, seatStatus)}</span>
      </p>
      {
        clientIsDeceased ?
          <p className='invite-status'>Client Reported As Deceased</p> :
          <p className='invite-status'>
            {seatStatus === 'Active' && 'Invitation Accepted'}
            {seatStatus === 'Declined' && `${props.client.first_name} declined your invitation to join Everplans`}
            {seatStatus === 'Denied' && `${props.activeClient.first_name} denied the request to add ${props.client.first_name} to this plan`}
            {seatStatus === 'Draft' && `${props.client.name} has not yet been invited to use Everplans`}
            {seatStatus === 'Pending' && 'Invitation sent but not yet accepted'}
            {seatStatus === 'Requesting Approval' && `${props.activeClient.first_name} must authorize adding ${props.client.first_name}`}
          </p>
      }
    </div>
  )
}

Status.defaultProps = {
  activeClient: {},
  client: {
    first_name: '',
    name: '',
    status: 'Draft'
  }
}

Status.propTypes = {
  activeClient: PropTypes.shape({
    first_name: PropTypes.string
  }),
  client: PropTypes.shape({
    deceased_at: PropTypes.string,
    first_name: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.oneOf([
      'Active',
      'Declined',
      'Denied',
      'Draft',
      'Household Declined',
      'Pending',
      'Requesting Approval'
    ])
  }),
  clientSeat: PropTypes.instanceOf(Map)
}

export default Status
