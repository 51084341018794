/** @class AccountInformation
 *
 * @desc
 * Information about an added, but not active client
 *
 * @todo move everything to v2 resources
 *
 */

import PropTypes from 'prop-types'
import {Map} from 'immutable'

import UserDetails from './UserDetails'
import Status from './Status'
import InviteClientLink from './InviteClientLink'

import './inactiveHouseholdUser.scss'

const InactiveHouseholdUser = props => {
  // Would be great to move to `Status` if it ever gets refactored --ZD
  const activeClient = props.clients.find(client => client.status === 'Active')
  const isInviteClientLinkVisible = !['Declined', 'Household Declined'].includes(props.clientSeat.get('status'))

  return (
    <div className='inactive-household-user'>
      <UserDetails
        client={props.client}
        everplanId={props.clientSeat.get('everplan-id')}
        userConfig={props.userConfig}
      />
      <Status
        activeClient={activeClient}
        client={props.client}
        clientSeat={props.clientSeat}
      />
      {isInviteClientLinkVisible && <InviteClientLink {...props} />}
    </div>
  )
}

InactiveHouseholdUser.propTypes = {
  client: PropTypes.shape({
    last_login: PropTypes.string,
    login_count: PropTypes.number
  }),
  clientSeat: PropTypes.instanceOf(Map),
  clients: PropTypes.array,
  userConfig: PropTypes.instanceOf(Map)
}

export default InactiveHouseholdUser
