import PropTypes from 'prop-types'
import {Box, List, ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material'
import {Check as CheckIcon, Close as CloseIcon} from '@mui/icons-material'

import './passwordRequirements.scss'

const requirements = [
  {key: 'length', text: 'At least 8 characters'},
  {key: 'uppercase', text: 'At least 1 uppercase letter'},
  {key: 'lowercase', text: 'At least 1 lowercase letter'},
  {key: 'number', text: 'At least 1 number'}
]

const PasswordRequirements = ({validations}) => (
  <Box className='password-requirements'>
    <Typography className='requirements-title'>
      Password must contain at least:
    </Typography>
    <List dense>
      {requirements.map(({key, text}) => (
        <ListItem key={key} className='requirement-item'>
          <ListItemIcon className='requirement-icon'>
            {validations[key] ?
              (
                <CheckIcon color='success' fontSize='small' />
              ) :
              (
                <CloseIcon color='error' fontSize='small' />
              )}
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      ))}
    </List>
  </Box>
)

PasswordRequirements.propTypes = {
  validations: PropTypes.shape({
    length: PropTypes.bool,
    uppercase: PropTypes.bool,
    lowercase: PropTypes.bool,
    number: PropTypes.bool
  }).isRequired
}

export default PasswordRequirements
