import PropTypes from 'prop-types'

import {Map} from 'immutable'

import Dev from '../../../../../dev_only'
import EditClientModalController from '../../clients/EditClientModalController'

import './userDetails.scss'

const UserDetails = props => {
  const editClientAvailable = props.client.status !== 'Active' &&
    props.client.status !== 'Denied' && !props.client.deceased_at

  return (
    <div className='user-details'>
      <p>
        <strong>{props.client.name}</strong>
        <Dev.Block> (ClientSeat ID: {props.client.client_seat_id})</Dev.Block>
      </p>
      <p className='user-email'>{props.client.email}</p>
      {
        editClientAvailable &&
        (
          <EditClientModalController
            {...props.client}
            everplanId={props.everplanId}
            clientSeatId={props.client.client_seat_id}
            userConfig={props.userConfig}
          />
        )
      }
    </div>
  )
}

UserDetails.defaultProps = {
  client: {
    email: '',
    name: ''
  }
}

UserDetails.propTypes = {
  client: PropTypes.shape({
    deceased_at: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    client_seat_id: PropTypes.number,
    status: PropTypes.oneOf([
      'Active',
      'Declined',
      'Denied',
      'Draft',
      'Household Declined',
      'Pending',
      'Requesting Approval'
    ])
  }),
  everplanId: PropTypes.number,
  userConfig: PropTypes.instanceOf(Map)
}

export default UserDetails
