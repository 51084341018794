import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import {activeAndInactiveOrInactiveInactiveEverplan} from '../../../../../lib/clientsEverplanStatus'
import {
  clientSeatByOwner,
  firstName,
  fullName
} from '../../../../../lib/removeClientsTools'

import NonActiveActiveHouseholdRemoveModal from './NonActiveActiveHouseholdRemoveModal'
import RemoveClientModal from './RemoveClientModal'

const RemoveClient = props => {
  const {owners, clientSeats} = props
  const isHousehold = props.everplan.get('is-household')
  const owner = owners.first()
  return (
    <div className='remove-client' data-testid='remove-client'>
      {activeAndInactiveOrInactiveInactiveEverplan({clientSeats, isHousehold}) ?
        (
          <NonActiveActiveHouseholdRemoveModal {...props} />
        ) :
        (
          <RemoveClientModal
            {...props}
            buttonText={`Remove${isHousehold ? ' Household' : ''}`}
            clientSeat={clientSeatByOwner({clientSeats, owner})}
            fullName={fullName({...props, isHousehold, owner})}
            firstName={firstName({...props, isHousehold, owner})}
            owner={owner}
          />
        )}
    </div>
  )
}


RemoveClient.propTypes = {
  clientSeats: PropTypes.instanceOf(List),
  everplan: PropTypes.instanceOf(Map),
  owners: PropTypes.instanceOf(List)
}

export default RemoveClient
