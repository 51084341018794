import PropTypes from 'prop-types'
import {Map} from 'immutable'
import {useRef, useState} from 'react'

import {
  useNotificationContext
} from '../../../../../shared_components/notifications/NotificationContext'

import Modal from '../../../../../shared_components/Modal'
import Forms from '../../../../../shared_components/forms'
import {Cancel, Continue} from '../../../../../shared_components/ButtonTypes'
import {notifyAfterSend} from '../../../../../lib/quarterbackInviteTools'

import './sendQuarterbackInviteElement.scss'

const SendQuarterbackInviteElement = ({
  quarterbackSeat, readEndpoint, updateResource, ...otherProps
}) => {
  const {alwaysNotify} = useNotificationContext()

  const formRef = useRef(null)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  const handleModalClose = () => {
    setIsModalVisible(false)
  }

  const handleModalOpen = () => {
    setIsModalVisible(true)
  }

  const handleSendInvitationClick = () => {
    setIsProcessing(true)
    setIsModalVisible(false)

    const form = formRef.current

    const payload = {
      type: 'quarterback-invites',
      id: quarterbackSeat.get('quarterback-invite-id'),
      attributes: {
        'custom-invite-text': form.formData().invite_text || '',
        ...(quarterbackSeat.get('status') === 'pending' && {resend: true})
      }
    }

    updateResource(payload).then(() => {
      notifyAfterSend({alwaysNotify, quarterbackSeat})

      readEndpoint(`quarterback-seats/${quarterbackSeat.get('id')}`)

      setIsProcessing(false)
    })
  }

  const sendInvitationElement = () => {
    const linkText = `${isProcessing ? 'Resending' : 'Resend'} invitation`

    if (quarterbackSeat.get('status') === 'pending') {
      return (
        <a
          disabled={isProcessing || otherProps.isProcessing}
          onClick={handleModalOpen}>
          {linkText}
        </a>
      )
    } else {
      return (
        <Continue
          disabled={isProcessing || otherProps.isProcessing}
          loading={isProcessing}
          onClick={handleModalOpen}>
          Send invitation
        </Continue>
      )
    }
  }

  return (
    <div className='send-quarterback-invite-element'>
      {sendInvitationElement()}
      <Modal closer={handleModalClose} visible={isModalVisible}>
        <div className='heading-section section'>
          <div className='modal-header'>Send Invitation Email</div>
        </div>
        <div className='form-section section'>
          <p>Add a personal message:</p>
          <Forms.Form ref={formRef} onSubmit={handleSendInvitationClick}>
            <Forms.TextArea autoFocus={true} name='invite_text' wrap={false} />
          </Forms.Form>
        </div>
        <div className='button-group'>
          <Cancel
            disabled={isProcessing}
            onClick={handleModalClose}
          />
          <Continue
            disabled={isProcessing}
            loading={isProcessing}
            onClick={handleSendInvitationClick}>
            Send Invitation
          </Continue>
        </div>
      </Modal>
    </div>
  )
}

SendQuarterbackInviteElement.propTypes = {
  quarterbackSeat: PropTypes.instanceOf(Map).isRequired,
  readEndpoint: PropTypes.func.isRequired,
  updateResource: PropTypes.func.isRequired
}

export default SendQuarterbackInviteElement
