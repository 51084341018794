import PropTypes from 'prop-types'

import {fromJS} from 'immutable'
import {useRef} from 'react'

import * as ButtonTypes from '../../../../shared_components/ButtonTypes'
import Form from '../../../../shared_components/forms/v2/Form'
import Fields from '../../../../shared_components/forms/v2/fields/index'
import Modal from '../../../../shared_components/Modal'
import validators from '../../../../shared_components/forms/v2/validators'

import './quarterbackSeatForm.scss'

const QuarterbackSeatForm = props => {
  const formRef = useRef()

  const submit = () => {
    const form = formRef.current

    form.onSubmit()

    if (form.isValid())
      props.submit(form.formData())
  }

  return (
    <div
      className='quarterback-seat-form forms-playground'
      data-testid='quarterback-seat-form'>
      <Modal closer={props.closeModal} visible={props.isModalVisible}>
        <div className='section'>
          <div className='title'>{props.modalHeaderText}</div>
        </div>
        <Form ref={formRef} className='core form section side-labels'>
          <Fields.Name
            data={
              fromJS({
                id: 'name',
                legend: 'Name:',
                name: 'name'
              })
            }
            validator={validators.requiredNameFirstLastValidator}
          />
          <Fields.Email
            data={fromJS({
              legend: 'Email Address:',
              name: 'email',
              id: 'email'
            })}
            validator={validators.emailValidator}
            data-testid='email'
          />
        </Form>
        <div className='button-group'>
          <ButtonTypes.Cancel
            disabled={props.isProcessing}
            onClick={props.closeModal}
          />
          <ButtonTypes.Continue
            disabled={props.isProcessing}
            loading={props.isProcessing}
            onClick={submit}>
            {props.actionText}
          </ButtonTypes.Continue>
        </div>
      </Modal>
    </div>
  )
}

QuarterbackSeatForm.propTypes = {
  actionText: PropTypes.string,
  closeModal: PropTypes.func,
  errors: PropTypes.array,
  isProcessing: PropTypes.bool,
  isModalVisible: PropTypes.bool,
  modalHeaderText: PropTypes.string,
  submit: PropTypes.func
}

export default QuarterbackSeatForm


