import PropTypes from 'prop-types'

import {useState} from 'react'
import {connect} from 'react-redux'
import {readEndpoint} from 'redux-json-api'

import QuarterbackSeatsTable from './QuarterbackSeatsTable'
import QuarterbackSeatsTableHeader from './QuarterbackSeatsTableHeader'
import routerUtils from '../../../../../lib/routerUtils'

import {filteredValueQuery, pageNumberAndSize} from '../../../../../lib/summariesTableTools'
import {cleanApiResponseData} from '../../../../../lib/tools'

export const QuarterbackSeatsTableContainer = props => {
  const [loading, setLoading] = useState(true)
  const [pages, setPages] = useState(-1)
  const [quarterbackSeats, setQuarterbackSeats] = useState([])

  const sortValue = tableState => {
    const sorted = tableState.sorted

    if (sorted.length)
      // Needed to account for multiple filters -- ZD
      return `&sort=${tableState.sorted[0].desc ? '-' : ''}${tableState.sorted[0].id}`
    else
      return ''
  }

  const readEndpointQuery = tableState => (
    tableState ?
      `${pageNumberAndSize(tableState)}${sortValue(tableState)}${filteredValueQuery(tableState)}` :
      'sort=-created-at'
  )

  const handleQuarterbackSeatsDataFetched = response => {
    setLoading(false)
    /**
      Need to get the data directly from the response, because the
      `redux-json-api` library just appends newly fetched data to already
      existing data, therefore if we use `mapStateToProps` the data will not be
      filtered or sorted correctly.
    */
    setQuarterbackSeats(cleanApiResponseData(response.body.data))
    setPages(response.body.meta['page-count'])
  }

  const fetchQuarterbackSeats = tableState => {
    setLoading(true)

    props.readEndpoint(
      `quarterback-seats?${readEndpointQuery(tableState)}`
    ).then(response => handleQuarterbackSeatsDataFetched(response))
  }

  const handleRowClick = quarterbackSeatId => {
    routerUtils.push(
      `pro/quarterback-seat-admin/quarterback-seats/${quarterbackSeatId}`
    )
  }

  return (
    <div>
      <QuarterbackSeatsTableHeader
        fetchQuarterbackSeats={fetchQuarterbackSeats}
        loading={loading}
      />
      <QuarterbackSeatsTable
        loading={loading}
        onFetchData={fetchQuarterbackSeats}
        onRowClick={handleRowClick}
        pages={pages}
        quarterbackSeats={quarterbackSeats}
      />
    </div>
  )
}

QuarterbackSeatsTableContainer.propTypes = {
  readEndpoint: PropTypes.func
}

export default connect(null, {readEndpoint})(QuarterbackSeatsTableContainer)
