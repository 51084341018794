import PropTypes from 'prop-types'
import {useFormContext} from 'react-hook-form'
import {Stack} from '@mui/material'

import Password from './Password'

const PasswordWithConfirmation = ({
  confirmLabel = 'Confirm Password',
  confirmName = 'password_confirmation',
  confirmPlaceholder,
  label = 'Password',
  name = 'password',
  placeholder
}) => {
  const {watch} = useFormContext()
  const password = watch(name)

  const validateConfirmation = value => {
    if (value !== password) return 'Passwords must match.'
  }

  return (
    <Stack spacing={2} className='password-with-confirmation'>
      <Password
        label={label}
        name={name}
        placeholder={placeholder}
        showRequirements={true}
      />
      <Password
        label={confirmLabel}
        name={confirmName}
        placeholder={confirmPlaceholder}
        showRequirements={false}
        validationRules={{
          validate: validateConfirmation
        }}
      />
    </Stack>
  )
}

PasswordWithConfirmation.propTypes = {
  confirmLabel: PropTypes.string,
  confirmName: PropTypes.string,
  confirmPlaceholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string
}

export default PasswordWithConfirmation
